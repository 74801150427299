import {TopBar} from "../menu/TopBar";
import LeftSideMenu from "../menu/LeftSideMenu";
import React from "react";
import "./PageWrapperWithMenu.scss";
import {useIsAdmin} from "../components/IsAdminProvider";
import config from "../components/config"

export const PageWrapperWithMenu = ({children, padding}) => {
    const isAdmin = useIsAdmin();

    if (padding === undefined) {
        padding = true;
    }

    const mainContentAreaClassName = `main-content-area ${padding ? "padding" : "no-padding"}`
    const pageBodyClassName = `page-body ${padding ? "padding" : "no-padding"}`


    return <div className="page-wrapper-with-menu">
        <TopBar/>
        <div className={"below-top-bar"}>

            {
                isAdmin || config.REACT_APP_HIDE_LEFT_MENU_WHEN_NOT_ADMIN === "false" ?
                    <LeftSideMenu/>
                : null
            }

            <div className={mainContentAreaClassName}>
                <div className={pageBodyClassName}>
                    {children}
                </div>
            </div>
        </div>
    </div>
};